import { HashRouter, BrowserRouter, Route, Switch, Link, Redirect, withRouter } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import './scss/components/loading.scss';
import './scss/components/popup-single-meeting.scss';
import './style.scss';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


import loginNavImg from './images/login-white.svg';
import homeNavImg from './images/home-white.svg';

import TodayInfo from './components/today-info';
import EventList from './components/events-list';

const Home = lazy(() => import('./component-pages/home'));
const AddEvent = lazy(() => import('./component-pages/add-event'));
const UpdateEvent = lazy(() => import('./component-pages/update-event'));
const DeleteEvent = lazy(() => import('./component-pages/delete-event'));
const VerifyEvent = lazy(() => import('./component-pages/verify-event'));
const LoginMeetingRoom = lazy(() => import('./component-pages/login-meeting-room'));
const SuccessPage = lazy(() => import('./component-pages/success-page'));

const renderLoader = () => <div className="loading-page"><LinearProgress></LinearProgress></div>;

class App extends React.Component {

	constructor(props) {
		super(props);
		this.meetingRoomChange = this.meetingRoomChange.bind(this);
		this.prevData = "";
		this.currentData = "";
		this.emailList = [];
		this.organizerEmailList = [];
		this.unmounted = false;
		this.singleMeetingDataHandler = this.singleMeetingDataHandler.bind(this);
		this.handlePopupClose = this.handlePopupClose.bind(this);
		this.getEmailListDataFromAjax = this.getEmailListDataFromAjax.bind(this);
		this.getOrganizerEmailListDataFromAjax = this.getOrganizerEmailListDataFromAjax.bind(this);
	}

	state = {
		meetingRoomName: "",
		currentlyMeetingRoomUse: 0,
		todayStatus: 1,
		nextMeetingData: [],
		todayMeetingData: [],
		organizerEmailList: [],
		meetingRoomImg: "",
		loginState: false,
		meetingRoomDataState: false,
		meetingRoomList: [],
		meetingRoomListSelected: "",
		meetingRoomListSelectedImage: "",
		meetingDataListLoadStatus: false,
		openModal: false,
		singleMeetingDataObj: {},
		updateDataObj: {},
		updateEventOrganizer: {},
	}

	handlePopupClose() {
		this.setState({
			openModal: false
		})
	}

	singleMeetingDataHandler(meetingData) {
		this.setState({
			openModal: true,
			singleMeetingDataObj: meetingData
		});
	}


	getEmailListDataFromAjax() {
		// let dataObj = "";
		let self = this;
		fetch(process.env.REACT_APP_API_URL+ "/php-api/call_ajax.php?action=getemaillist", {
			method: 'GET',
		})
			.then(function (response) { return response.json() })
			.then(function (data) {				
				if(data.emaillist.length){
					self.setState({
						emailList: data.emaillist,
                    });
				}
			})
			.catch(function (error) {
				/*Handle error*/
				console.log("error", error);
			});
		
	}

	getOrganizerEmailListDataFromAjax() {
		// let dataObj = "";
		let self = this;
		let meetingRoomCalendarId = "";
		let meetingRoomData = localStorage.getItem('meetingRoomData');

		if(meetingRoomData){
			meetingRoomCalendarId = JSON.parse(meetingRoomData).calendar_id;
		}

		let organizerPostData = {
			action: "getorganizeremaillist",
			cal_id : meetingRoomCalendarId,
		}
		fetch(process.env.REACT_APP_API_URL+ "/php-api/call_ajax.php", {
			method: 'POST',
			headers: {
					'Content-Type': 'text/html; charset=UTF-8'
				},
			body: JSON.stringify(organizerPostData)
		})
		.then(function (response) { 
			return response.json() 
		})
		.then(function (data) {			
			if(data.emaillist.length){
				self.setState({
					organizerEmailList: data.emaillist,
				})
			}		
		})
		.catch(function (error) {
			/*Handle error*/
			console.log("error", error);
		});
		
	}

	getMeetingRoomListFromAjax() {
		let self = this;
		fetch(process.env.REACT_APP_API_URL + "/php-api/call_ajax.php?action=getroomdata", {
			method: 'GET',
		})
			.then(function (response) {
				if (self.unmounted) return;
				return response.json()
			})
			.then(function (data) {
				let meetingRoomData = localStorage.getItem('meetingRoomData');

				if (meetingRoomData) {
					self.setState({
						meetingRoom: JSON.parse(meetingRoomData),
					})
				}
				if (data.caldata.length) {
					self.setState({
						meetingRoomList: data.caldata,
						isLoaded: true,
					});

					//preload meeting room images
					let meetingRoomsArr = data.caldata;
					meetingRoomsArr.forEach((imgObj) => {						
						let meetingRoomImage = process.env.REACT_APP_API_URL + imgObj.image;
						const img = new Image();
						img.src = meetingRoomImage;
					});
				}
			})
			.catch(function (error) {
				/*Handle error*/
				console.log("error", error);
			});
	}


	getDataFromAjax() {

		let meetingRoomData = localStorage.getItem('meetingRoomData');

		if (meetingRoomData) {

			let meetingRoomCalendarId = JSON.parse(meetingRoomData).calendar_id;
			let meetingRoomId = JSON.parse(meetingRoomData).id;
			// console.log(meetingRoomId);
			if (meetingRoomId) {
				let self = this;
				fetch(process.env.REACT_APP_API_URL + "/php-api/call_ajax.php?action=eventlist&cal_id=" + meetingRoomId, {
					method: 'GET',
				})
					.then(function (response) {
						if (self.unmounted) return;
						return response.json()
					})
					.then(function (data) {
						// console.log("data", data);

						if (data) {
							self.currentData = data;

							let meetingData = []
							if (data.todayStatus) {
								meetingData = data.todayData;
							} else {
								meetingData = []
							}

							self.setState({
								// meetingRoomName: data.caption,
								currentlyMeetingRoomUse: data.validateStatus,
								todayStatus: data.todayStatus,
								nextMeetingData: data.data,
								todayMeetingData: meetingData,
								todayDate: data.todayDate,
								meetingDataListLoadStatus: false
							})

						}

					})
					.catch(function (error) {
						/*Handle error*/
						console.log("error", error);
					});
			}
		} else {

			this.setState({
				meetingRoomDataState: false
			})

		}
	}

	// select meeting room according to local storage or set 1st meeting room
	selectDefaultRoom() {
		let meetingRoomData = localStorage.getItem('meetingRoomData');

		if (meetingRoomData) {

			let meetingRoomId = JSON.parse(meetingRoomData).id;
			let meetingRoomImage = process.env.REACT_APP_API_URL + JSON.parse(meetingRoomData).image;
			this.setState({
				meetingRoomListSelected: meetingRoomId,
				meetingRoomListSelectedImage: meetingRoomImage
			});

		} else {

			this.setState({
				meetingRoomListSelected: 1,
			});

			//have to wait until get response from php server
			setTimeout(() => {
				let selectedMeetingRoomObj = this.state.meetingRoomList[0];
				if(selectedMeetingRoomObj){
					let meetingRoomImage = process.env.REACT_APP_API_URL + selectedMeetingRoomObj.image;
					this.setState({
						meetingRoomListSelectedImage: meetingRoomImage
					});
				}

			}, 2000);
		}
	}

	// render meeting room options
	renderMeetingRoomOptions() {
		return this.state.meetingRoomList.map((dt, i) => {
			return (
				<MenuItem
					key={i}
					value={dt.id}>
					{dt.meeting_rm_name}
				</MenuItem>
			);
		});
	}

	meetingRoomChange(event) {
		//loader display
		this.setState({
			meetingDataListLoadStatus: true
		})
		this.setState({
			meetingRoomListSelected: event.target.value,
		});

		let arrIndex = parseInt(event.target.value) - 1;
		let selectedMeetingRoomObj = this.state.meetingRoomList[arrIndex];
		let meetingRoomImage = process.env.REACT_APP_API_URL + selectedMeetingRoomObj.image;
		this.setState({
			meetingRoomName: selectedMeetingRoomObj.meeting_rm_name,
			meetingRoomListSelectedImage: meetingRoomImage
		});

		//set selected meeting room obj
		localStorage.setItem("meetingRoomData", JSON.stringify(selectedMeetingRoomObj));
		//load meeting room event list
		this.getDataFromAjax();
		window.location.reload(false);
	}

	meetingRoomSet() {
		let meetingRoomData = localStorage.getItem('meetingRoomData');

		if (meetingRoomData) {
			let meetingRoomName = JSON.parse(meetingRoomData).meeting_rm_name;

			this.setState({
				meetingRoomName: meetingRoomName
			});
		}
	}

	componentDidMount() {

		this.getDataFromAjax();
		this.getMeetingRoomListFromAjax();
		this.selectDefaultRoom();
		this.meetingRoomSet();

		this.setState({
			meetingRoomImg: require('./images/mt-room-2.jpg')
		})

		this.interval = setInterval(() => {
			this.getDataFromAjax();
			this.getOrganizerEmailListDataFromAjax();
		}, 10000);

	}

	componentWillUnmount() {
		this.unmounted = true;
		clearInterval(this.interval);
		clearInterval(this.intervalLoginState);
	}

	render() {
		return (
			<HashRouter basename="/">
				<div className={"main-app " + (this.state.currentlyMeetingRoomUse ? 'on-meeting' : '')} style={{ backgroundImage: `url(${this.state.meetingRoomListSelectedImage})` }}>
					
					<Dialog
						fullWidth={true}
						maxWidth={'sm'}
						open={this.state.openModal}
						onClose={this.handlePopupClose}
						className="single-meeting-popup"
					>
						<div className="btn-area">
							<Link className="navigation-link edit-icon" onClick={this.handlePopupClose} to={`/update-event/${this.state.singleMeetingDataObj.evId}/${this.state.singleMeetingDataObj.meetingRoomId}`} >
								<EditIcon></EditIcon>
							</Link> 	
							<Link className="navigation-link delete-icon" onClick={this.handlePopupClose} to={`/delete-event/${this.state.singleMeetingDataObj.evId}/${this.state.singleMeetingDataObj.meetingRoomId}`} >
								<DeleteForeverIcon></DeleteForeverIcon>
							</Link> 	
						</div>	
						<DialogTitle>
							{this.state.singleMeetingDataObj.evTitle}  
							
						</DialogTitle>

						<div className="item-list">
							<Divider variant="fullWidth" component="div" />
							<div className="list-item">
								<div className="title-name">
									Description
								</div>
								<div className="text-value item-description-html-view" >
									{
										this.state.singleMeetingDataObj.evDescription ? (
											<span dangerouslySetInnerHTML={{
												__html: this.state.singleMeetingDataObj.evDescription
											}}></span>
										) : (
												<span>No Description</span>
											)
									}
								</div>
							</div>
							<Divider variant="fullWidth" component="div" />
							<div className="list-item">
								<div className="title-name">
									Date
								</div>
								<div className="text-value">
									{
										this.state.singleMeetingDataObj.evStartDate ? (
											<span>{this.state.singleMeetingDataObj.evStartDate}</span>

										) : (
												<span> - </span>
											)
									}
								</div>
							</div>
							<Divider variant="fullWidth" component="div" />
							<div className="list-item">
								<div className="title-name">
									Start Time
								</div>
								<div className="text-value">
									{
										this.state.singleMeetingDataObj.evStartTime ? (
											<span>{this.state.singleMeetingDataObj.evStartTime}</span>

										) : (
												<span> - </span>
											)
									}
								</div>
							</div>
							<Divider variant="fullWidth" component="div" />
							<div className="list-item">
								<div className="title-name">
									End Time
								</div>
								<div className="text-value">
									{
										this.state.singleMeetingDataObj.evEndTime ? (
											<span>{this.state.singleMeetingDataObj.evEndTime}</span>

										) : (
												<span> - </span>
											)
									}
								</div>
							</div>
							<Divider variant="fullWidth" component="div" />
							<div className="list-item">
								<div className="title-name">
									Organizer
								</div>
								<div className="text-value">
									{
										this.state.singleMeetingDataObj.evCreater ? (
											<span>{this.state.singleMeetingDataObj.evCreater}</span>
										) : (
												<span>No Creater</span>
											)
									}
								</div>
							</div>
							<Divider variant="fullWidth" component="div" />

							<div className="list-item">
								<div className="title-name">
									Attendees
								</div>
								<div className="text-value item-attendees">
									<ul>

										{
											this.state.singleMeetingDataObj.evAttendees ? (
												this.state.singleMeetingDataObj.evAttendees.map(function (evAttendent, evAttendentIdx) {

													return (

														<li key={evAttendentIdx} >
															{
																evAttendent.displayName ? (
																	<span>{evAttendent.displayName}</span>

																) : (
																		<span>{evAttendent.email}</span>
																	)
															}
														</li>
													);
												})

											) : (
													<li>No Others</li>
												)
										}
									</ul>
								</div>
							</div>
						</div>
					</Dialog>
					<div className="left-container">
						<Suspense fallback={renderLoader()}>
							<Switch>
								<Route exact path="/">
									<Home
										meetingRoomName={this.state.meetingRoomName}
										currentlyMeetingRoomUse={this.state.currentlyMeetingRoomUse}
										todayStatus={this.state.todayStatus}
										nextMeetingData={this.state.nextMeetingData}
										todayMeetingData={this.state.todayMeetingData}
										organizerEmailList={this.state.organizerEmailList}
									></Home>
								</Route>
								<Route path="/add-event" >
									<AddEvent
										meetingRoomName={this.state.meetingRoomName}
										loginStatus={this.state.loginStatus}
										organizerEmailList={this.state.organizerEmailList}
									></AddEvent>
								</Route>
								<Route path="/update-event/:eventId/:meetingRoomId" >
									<UpdateEvent
										meetingRoomName={this.state.meetingRoomName}
										loginStatus={this.state.loginStatus}
										singleMeetingDataObj={this.state.singleMeetingDataObj}
										emailList={this.state.emailList}
										organizerEmailList={this.state.organizerEmailList}
									></UpdateEvent>
								</Route>
								<Route path="/delete-event/:eventId/:meetingRoomId" >
									<DeleteEvent
										meetingRoomName={this.state.meetingRoomName}
										loginStatus={this.state.loginStatus}
										singleMeetingDataObj={this.state.singleMeetingDataObj}
										emailList={this.state.emailList}
										organizerEmailList={this.state.organizerEmailList}
									></DeleteEvent>
								</Route>
								<Route path="/verify-event" component={VerifyEvent} />
								<Route path="/login" component={LoginMeetingRoom} />
								<Route path="/success" component={SuccessPage} />

							</Switch>
						</Suspense>
					</div>
					<div className="right-container">
						<Select
							className="meeting-room-select"
							value={this.state.meetingRoomListSelected}
							onChange={this.meetingRoomChange}
						>
							{this.renderMeetingRoomOptions()}
						</Select>
						<div className="navigation-links">
							{/* <Link to="/login" className="navigation-link">
								<img src={loginNavImg} className="img-responsive" />
							</Link> */}
							<Link to="/" className="navigation-link">
								<img src={homeNavImg} className="img-responsive" />
							</Link>
						</div>
						<div className="right-container-inner-wrapper">
							<div className="right-section-1">
								<TodayInfo
									todayMeetingData={this.state.todayMeetingData}
									todayDate={this.state.todayDate}
								/>
							</div>
							<div className="right-section-2">
								<div className="message-component">
									Upcoming Meetings

									{
										(() => {

											if (this.state.meetingDataListLoadStatus == true) {

												return (
													<CircularProgress className="circular-progress" color="primary" size={15} thickness={5} />
												)
											}
										})()
									}

								</div>
							</div>
							<div className="right-section-3">
								{/* <Scrollbars > */}
									<EventList
										todayStatus={this.state.todayStatus}
										nextMeetingData={this.state.nextMeetingData}
										todayMeetingData={this.state.todayMeetingData}
										singleMeeting={this.singleMeetingDataHandler}
									/>
								{/* </Scrollbars> */}
							</div>
							<div className="right-section-4">
								<div className="event-actions">
									{/* <Link to="/login" className="btn reserve-btn"> Login </Link> */}
									<Link to="/add-event" className="btn reserve-btn"> + Reserve Now </Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</HashRouter>
		);
	}
}
export default App;
