import React from 'react';
import '../scss/components/event-list.scss';

class EventList extends React.Component {

    constructor(props){
		super(props);
    }

    render() {
        let _this = this;

        const nextMeetingData = this.props.nextMeetingData;
        const todayMeetingData = this.props.todayMeetingData;
        
        let todayMeetingDataAvailable;
        let nextDaysMeetingDataAvailable;

        if(todayMeetingData){
            if(todayMeetingData.length){
                todayMeetingDataAvailable = todayMeetingData[0].eventlist.length;
            }
        }
        
        if(nextMeetingData){
            if( nextMeetingData.length){
                nextDaysMeetingDataAvailable = nextMeetingData[0].eventlist.length;
            }
        }

        return(
            <div className="events-component">  
                <div className="events-list-wrapper">
                    <div className="event-group-component">
                        <div className="event-date-text"> Today </div>
                        <ul className="events-list-items">
                            {
                                //check today meetings available or not
                                todayMeetingDataAvailable ? (
                                    todayMeetingData[0].eventlist.map(function(meetingItems, meetingIdx) {

                                        return (
                                            <li className="events-list-item today-items" onClick={()=>_this.props.singleMeeting(meetingItems)}   key={ meetingIdx }>
                                                <div className="list-left-container">
                                                    <div className="event-name">{ meetingItems.evTitle }</div>
                                                    <div className="event-organizer">Organizer : { meetingItems.evCreater }</div>
                                                </div>
                                                <div className="list-right-container">
                                                    <div className="event-duration">
                                                        {meetingItems.evStartTime}
                                                        - 
                                                        { meetingItems.evEndTime }
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })

                                ) : (
                                    <li className="events-list-item">
                                        <div className="list-left-container">
                                            <div className="event-name"> No Meetings Available</div>
                                            <div className="event-organizer"></div>
                                        </div>
                                    </li>
                                )
                            }
                        </ul> 
                    </div>

                    {
                        nextDaysMeetingDataAvailable ? (

                            nextMeetingData.map(function(nextMeetingDate, key) {
                               
                                return (
                                    <div className="event-group-component" key={ key }>
                                        <div className="event-date-text"> { nextMeetingDate.date } - { nextMeetingDate.day }</div>
                                        <ul className="events-list-items">
                                            {
                                                nextMeetingDate.eventlist.length ? (
                                                    nextMeetingDate.eventlist.map((meetingItems , meetingIdx) => {

                                                        return (
                                                            <li className="events-list-item" onClick={()=>_this.props.singleMeeting(meetingItems)}   key={ meetingIdx }>
                                                                <div className="list-left-container">
                                                                    <div className="event-name">{ meetingItems.evTitle }</div>
                                                                    <div className="event-organizer">Organizer : { meetingItems.evCreater }</div>
                                                                </div>
                                                                <div className="list-right-container">
                                                                    <div className="event-duration">
                                                                        {meetingItems.evStartTime}
                                                                        - 
                                                                        { meetingItems.evEndTime }
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })
                                                ) : (
                                                    <li className="events-list-item">
                                                        <div className="list-left-container">
                                                            <div className="event-name"> No Meetings Available</div>
                                                            <div className="event-organizer"></div>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                );
                            })
                        
                        ) : (
                            <div className="event-group-component">
                                <div className="event-date-text"> Next </div>
                                <ul className="events-list-items">
                                    <li className="events-list-item">
                                        <div className="list-left-container">
                                            <div className="event-name"> No Meetings Available</div>
                                            <div className="event-organizer"></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )
                    }

                </div>
                {/* events-list-wrapper */}
            </div>
        );
    }
}

export default EventList;