import React from 'react';
import moment from 'moment-timezone';
import '../scss/components/today-info.scss';

class TodayInfo extends React.Component {
    constructor(props) {
        super(props);

        let passedDateObj = this.props.todayDate;
        let currentDateText = "";

        if(passedDateObj){
            currentDateText = moment(passedDateObj.date).format('dddd, D MMM YYYY');
        }

        let currentTimeObj = moment().tz("Asia/Colombo");
        let currentTime = currentTimeObj.format("HH:mm");
        let currentTimeSeconds = currentTimeObj.format("ss");
        let currentGreetingText = this.getGreeting(currentTimeObj);

        this.state = {
            curTime: currentTime,
            curTimeSeconds: currentTimeSeconds,
            curDateText: currentDateText,
            curGreetingText: currentGreetingText
        };
    }

    //get Greeting text according to time
    getGreeting(m) {
 
        var g = null; //return g

        if(!m || !m.isValid()) { return; } //if we can't find a valid or filled moment, we return.
	
        var split_afternoon = 12 //24hr time to split the afternoon
        var split_evening = 16 //24hr time to split the evening
        var currentHour = parseFloat(m.format("HH"));
        
        if(currentHour >= split_afternoon && currentHour <= split_evening) {
            g = "Good Afternoon !";
        } else if(currentHour >= split_evening) {
            g = "Good Evening !";
        } else {
            g = "Good Morning !";
        }
        
        return g;
    }

    componentDidMount() {
        this.interval = setInterval( () => {

            let passedDateObj = this.props.todayDate;
            let currentDateText = "";

            if(passedDateObj){
                currentDateText = moment(passedDateObj.date).format('dddd, D MMM YYYY');
            }

            let currentTimeObj = moment().tz("Asia/Colombo");
            let currentTime = currentTimeObj.format("HH:mm");
            let currentTimeSeconds = currentTimeObj.format("ss");
            
            let currentGreetingText = this.getGreeting(currentTimeObj);
            this.setState({
                curTime : currentTime,
                curTimeSeconds: currentTimeSeconds,
                curDateText: currentDateText,
                curGreetingText: currentGreetingText
            })
        },1000)
    }

    componentWillUnmount() {
		clearInterval(this.interval);
	}

    render() {
        return(
            <div className="today-info-component">
                <div className="time-wrapper"> <span> {this.state.curTime} </span> <span className="seconds-wrapper">: {this.state.curTimeSeconds} </span></div>
                <div className="date-wrapper"> {this.state.curDateText} </div>
                <div className="time-message"> {this.state.curGreetingText}</div>
            </div>
        );
    }
}

export default TodayInfo;